import createStore from "zustand";
import { Course } from "components/Card/interfaces";

export type CartStore = {
  currentCart: Course[] | null;
  updateCart: (item: Course) => void;
  postCart: (data: Course[]) => void;
};

export const useCartStore = createStore<CartStore>((set) => ({
  currentCart: [],
  updateCart(item: Course) {
    set((state) => ({
      currentCart: [...state.currentCart].find((a) => a.id === item.id)
        ? [...state.currentCart].filter((x) => x.id !== item.id)
        : [...state.currentCart, {...item}],
    }));
  },
  postCart(data: Course[]) {
    set({
      currentCart: data,
    });
  },
}));
