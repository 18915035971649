// import Const from "../helpers/const"

import { CONST } from "./const";

export type CloudflareOptions = {
  width?: number;
  height?: number;
  quality?: number;
  // more options
  //https://developers.cloudflare.com/images/image-resizing/url-format/#options
};

export const isImageResizable = (sourceImage: string) => {
  const imageUrl = new URL(sourceImage);
  const imageHosts = CONST.IMAGE_HOSTS.split(",");

  return imageHosts.includes(imageUrl.host);
};

export const resizeImageUrl = (
  sourceImage: string,
  options: CloudflareOptions
) => {
  try {
    const imageUrl = new URL(sourceImage);

    if (isImageResizable(sourceImage)) {
      const pathname = imageUrl.pathname + imageUrl.search;

      const { width, height, quality } = options;

      let optionsText = width ? `width=${width}` : "";
      if (height) {
        optionsText += `,height=${height}`;
      }
      if (quality) {
        optionsText += `,quality=${quality}`;
      } else {
        optionsText += `,quality=75`;
      }
      if (width && height) {
        optionsText += `,fit=cover`;
      }
      if (width || height) {
        optionsText += `,format=auto`;
      }
      return `${imageUrl.origin}/cdn-cgi/image/${optionsText}${pathname}`;
    } else {
      return sourceImage;
    }
  } catch {
    return sourceImage;
  }
};
