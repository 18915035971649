import { useSystemStore } from "components/PageHeader/systemStore";
import React from "react";

type Props = {
  size?: number;
  color?: string;
  direction?: "left" | "right" | "bottom" | "up";
  isCaret?: boolean;
  isCircle?: boolean;
  className?: string;
};

export const IconArrow: React.FC<Props> = ({
  size = 20,
  color = "#19093A",
  direction = "left",
  isCaret = false,
  isCircle = false,
  className = "fill-purple50_to_white",
}) => {
  const theme = useSystemStore((state) => state.theme);
  switch (direction) {
    case "left":
      className = "rotate-180";
      break;
    case "bottom":
      className = "rotate-90";
      break;

    case "up":
      className = "-rotate-90";
      break;
  }
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isCaret ? (
        <path
          d="M15.5397 11.2895L9.87974 5.63955C9.78677 5.54582 9.67617 5.47143 9.55431 5.42066C9.43246 5.36989 9.30175 5.34375 9.16974 5.34375C9.03773 5.34375 8.90702 5.36989 8.78516 5.42066C8.6633 5.47143 8.5527 5.54582 8.45974 5.63955C8.27349 5.82691 8.16895 6.08036 8.16895 6.34455C8.16895 6.60873 8.27349 6.86219 8.45974 7.04955L13.4097 12.0495L8.45974 16.9995C8.27349 17.1869 8.16895 17.4404 8.16895 17.7045C8.16895 17.9687 8.27349 18.2222 8.45974 18.4095C8.55235 18.504 8.6628 18.5792 8.78467 18.6307C8.90655 18.6822 9.03743 18.709 9.16974 18.7095C9.30204 18.709 9.43293 18.6822 9.5548 18.6307C9.67668 18.5792 9.78712 18.504 9.87974 18.4095L15.5397 12.7595C15.6412 12.6659 15.7223 12.5523 15.7777 12.4258C15.8331 12.2993 15.8617 12.1627 15.8617 12.0245C15.8617 11.8864 15.8331 11.7498 15.7777 11.6233C15.7223 11.4968 15.6412 11.3832 15.5397 11.2895Z"
          fill={theme == "dark" ? "#FFFFFF" : color}
        />
      ) : isCircle ? (
        <path
          d="M14.86 12.5269L11.0867 8.76019C11.0247 8.6977 10.951 8.64811 10.8697 8.61426C10.7885 8.58041 10.7014 8.56299 10.6134 8.56299C10.5254 8.56299 10.4382 8.58041 10.357 8.61426C10.2757 8.64811 10.202 8.6977 10.14 8.76019C10.0159 8.8851 9.94617 9.05406 9.94617 9.23019C9.94617 9.40631 10.0159 9.57528 10.14 9.70019L13.44 13.0335L10.14 16.3335C10.0159 16.4584 9.94617 16.6274 9.94617 16.8035C9.94617 16.9796 10.0159 17.1486 10.14 17.2735C10.2018 17.3365 10.2754 17.3866 10.3567 17.421C10.4379 17.4553 10.5252 17.4732 10.6134 17.4735C10.7016 17.4732 10.7888 17.4553 10.8701 17.421C10.9513 17.3866 11.025 17.3365 11.0867 17.2735L14.86 13.5069C14.9277 13.4444 14.9817 13.3687 15.0186 13.2843C15.0556 13.2 15.0747 13.1089 15.0747 13.0169C15.0747 12.9248 15.0556 12.8337 15.0186 12.7494C14.9817 12.665 14.9277 12.5893 14.86 12.5269Z"
          fill={theme == "dark" ? "#FFFFFF" : color}

        />
      ) : (
        <path
          d="M4.16272 9.1706L4.16277 9.0706L4.16291 9.0706C4.16298 9.0706 4.16306 9.0706 4.16313 9.0706L14.4442 9.07631L11.0153 5.64742C10.8416 5.47376 10.7441 5.23823 10.7441 4.99264C10.7441 4.74705 10.8416 4.51152 11.0153 4.33786C11.1889 4.1642 11.4245 4.06665 11.6701 4.06665C11.9156 4.06665 12.1512 4.16421 12.3248 4.33786L17.3322 9.3452L17.333 9.34606L17.333 9.34606C17.4174 9.43255 17.4841 9.53473 17.5293 9.64683C17.6226 9.87302 17.6226 10.1269 17.5293 10.3531C17.4841 10.4652 17.4174 10.5674 17.333 10.6539L17.3322 10.6548L17.3322 10.6548L12.3248 15.6621C12.1512 15.8358 11.9156 15.9333 11.6701 15.9333C11.4245 15.9333 11.1889 15.8358 11.0153 15.6621C10.8416 15.4884 10.7441 15.2529 10.7441 15.0073C10.7441 14.7617 10.8416 14.5262 11.0153 14.3525L14.4442 10.9236L4.16313 10.9294C4.16306 10.9294 4.16298 10.9294 4.16291 10.9294L4.16278 10.9294L4.16272 10.8294C4.05368 10.8298 3.94563 10.8087 3.8448 10.7671C3.74398 10.7256 3.65237 10.6645 3.57527 10.5874C3.49816 10.5103 3.43709 10.4187 3.39557 10.3179C3.35405 10.2171 3.3329 10.109 3.33335 9.99998M4.16272 9.1706C4.05368 9.17016 3.94563 9.1913 3.8448 9.23282C3.74398 9.27435 3.65237 9.33542 3.57527 9.41252C3.49816 9.48963 3.43709 9.58124 3.39557 9.68206C3.35405 9.78289 3.3329 9.89094 3.33335 9.99998M4.16272 9.1706L14.5442 9.17637L4.16272 9.1706ZM3.33335 9.99998L3.23335 9.99957C3.23335 9.9997 3.23334 9.99984 3.23334 9.99998C3.23334 10.0001 3.23335 10.0002 3.23335 10.0004L3.33335 9.99998Z"
          fill={theme == "dark" ? "#FFFFFF" : color}
          stroke={color}
          strokeWidth="0.2"
        />
      )}
    </svg>
  );
};
