import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const IconPlusCircle: React.FC<Props> = ({
  size = 20,
  color = "#318A4E",
}) => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_2114_5089)">
        <rect x="6.5" y="2" width="36" height="36" rx="18" fill="white" />
        <path
          d="M30.333 19.1667H25.333V14.1667C25.333 13.9456 25.2452 13.7337 25.0889 13.5774C24.9326 13.4211 24.7207 13.3333 24.4997 13.3333C24.2787 13.3333 24.0667 13.4211 23.9104 13.5774C23.7541 13.7337 23.6663 13.9456 23.6663 14.1667V19.1667H18.6663C18.4453 19.1667 18.2334 19.2545 18.0771 19.4107C17.9208 19.567 17.833 19.779 17.833 20C17.833 20.221 17.9208 20.433 18.0771 20.5893C18.2334 20.7455 18.4453 20.8333 18.6663 20.8333H23.6663V25.8333C23.6663 26.0543 23.7541 26.2663 23.9104 26.4226C24.0667 26.5789 24.2787 26.6667 24.4997 26.6667C24.7207 26.6667 24.9326 26.5789 25.0889 26.4226C25.2452 26.2663 25.333 26.0543 25.333 25.8333V20.8333H30.333C30.554 20.8333 30.766 20.7455 30.9223 20.5893C31.0785 20.433 31.1663 20.221 31.1663 20C31.1663 19.779 31.0785 19.567 30.9223 19.4107C30.766 19.2545 30.554 19.1667 30.333 19.1667Z"
          fill="#19093A"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2114_5089"
          x="0.5"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2114_5089"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2114_5089"
            result="effect2_dropShadow_2114_5089"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2114_5089"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
