type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Chip: React.FC<Props> = (props) => {
  return (
    <div
      className={`h-24 flex items-center px-10 text-b2 bg-neutral-10 rounded-full ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};
