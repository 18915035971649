import { Image } from "components/Image/Image";

type Props = {
  image_url?: string;
  size?: number;
  padding?: number;
  useResizeImage?: boolean;
  styles?: string
};

export const LogoChip: React.FC<Props> = ({
  image_url,
  size = 60,
  padding = 4,
  useResizeImage = false,
  ...props
}) => {
  return (
    <div
      className={`bg-logo_chip rounded-lg p-[${padding}px] w-${size} h-${size} flex justify-center items-center aspect-square ${props?.styles ?? ""}`}
    >
      {image_url ? (
        useResizeImage ? (
          <Image
            src={image_url}
            alt={image_url}
            width={size-padding}
            height={size-padding}
            className={`object-contain object-center rounded-lg`}
          />
        ) : (
          <img
            src={image_url}
            className="w-full h-full object-cover rounded-lg"
          />
        )
      ) : null}
    </div>
  );
};
