import { useSystemStore } from "components/PageHeader/systemStore";
import React, { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Variant, BtnState } from ".";

type Props = {
  variant?: Variant;
  state?: BtnState;
  children: ReactNode;
};

const ButtonContent: React.FC<Props> = ({
  variant = "primary",
  state = "active",
  children,
}) => {
  const themeMode = useSystemStore((state) => state.theme);

  return (
    <>
      {state === "loading" ? (
        <div>
          <ClipLoader
            size={16}
            // color={variant === "secondary" ? "black" : "white"}
            color={themeMode == "dark" ? "#FFFFFF" : "#817E8C"}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ButtonContent;
