import { boolean } from "zod";

type Props = {
  color?: string;
  size?: number;
  fill?: boolean;
  half?: boolean;
};

export const IconStar: React.FC<Props> = ({
  color = "#551FC1",
  size = 32,
  fill = false,
  half = false,
}) => {
  if (fill) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.333 12.8935C29.2488 12.6496 29.0956 12.4354 28.8921 12.2769C28.6886 12.1183 28.4434 12.0222 28.1863 12.0002L20.5996 10.8935L17.1996 4.00019C17.0905 3.77477 16.92 3.58465 16.7078 3.45162C16.4955 3.3186 16.2501 3.24805 15.9996 3.24805C15.7492 3.24805 15.5038 3.3186 15.2915 3.45162C15.0793 3.58465 14.9088 3.77477 14.7996 4.00019L11.3996 10.8802L3.81298 12.0002C3.56621 12.0353 3.33421 12.1388 3.14331 12.2991C2.95241 12.4593 2.81026 12.6699 2.73298 12.9069C2.66224 13.1384 2.65589 13.3849 2.71461 13.6198C2.77334 13.8547 2.89492 14.0692 3.06631 14.2402L8.57298 19.5735L7.23964 27.1469C7.19204 27.3968 7.21697 27.6552 7.31147 27.8915C7.40598 28.1277 7.56612 28.332 7.77298 28.4802C7.9746 28.6243 8.21241 28.7094 8.45971 28.7259C8.70701 28.7424 8.95401 28.6896 9.17298 28.5735L15.9996 25.0135L22.7996 28.5869C22.9868 28.6924 23.1981 28.7476 23.413 28.7469C23.6954 28.7479 23.9709 28.6592 24.1996 28.4935C24.4065 28.3453 24.5666 28.1411 24.6611 27.9048C24.7557 27.6685 24.7806 27.4102 24.733 27.1602L23.3996 19.5869L28.9063 14.2535C29.0988 14.0904 29.2411 13.8761 29.3166 13.6353C29.3922 13.3946 29.3978 13.1374 29.333 12.8935Z"
          fill={color}
        />
      </svg>
    );
  } else if (half) {
    return (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3329 5.6408C13.296 5.5341 13.229 5.44038 13.14 5.37101C13.0509 5.30163 12.9437 5.25959 12.8312 5.24996L9.51204 4.7658L8.02454 1.74996C7.97677 1.65134 7.90219 1.56816 7.80934 1.50996C7.71649 1.45177 7.60912 1.4209 7.49954 1.4209C7.38996 1.4209 7.28259 1.45177 7.18974 1.50996C7.09689 1.56816 7.02231 1.65134 6.97454 1.74996L5.48704 4.75996L2.16787 5.24996C2.05991 5.26531 1.95841 5.31061 1.87489 5.38072C1.79138 5.45084 1.72918 5.54296 1.69537 5.64663C1.66442 5.74794 1.66165 5.85576 1.68734 5.95853C1.71303 6.0613 1.76622 6.15514 1.84121 6.22996L4.25037 8.5633L3.66704 11.8766C3.64349 11.9865 3.6522 12.1009 3.69211 12.206C3.73203 12.3111 3.80146 12.4024 3.89205 12.4689C3.98264 12.5355 4.09052 12.5745 4.20273 12.5811C4.31494 12.5878 4.42669 12.562 4.52454 12.5066L7.49954 10.9491L10.4745 12.5066C10.5564 12.5528 10.6489 12.5769 10.7429 12.5766C10.8664 12.5771 10.987 12.5383 11.087 12.4658C11.1775 12.401 11.2476 12.3116 11.2889 12.2082C11.3303 12.1049 11.3412 11.9918 11.3204 11.8825L10.737 8.56913L13.1462 6.2358C13.2304 6.16444 13.2927 6.07066 13.3257 5.96533C13.3588 5.86001 13.3612 5.74747 13.3329 5.6408ZM9.71621 7.94496C9.64869 8.01053 9.59804 8.09147 9.56858 8.18086C9.53912 8.27024 9.53173 8.36543 9.54704 8.4583L9.96704 10.9083L7.49954 9.33328V4.08328L8.59621 5.5533C8.63823 5.63895 8.70062 5.71299 8.77791 5.76893C8.85521 5.82486 8.94504 5.86098 9.03954 5.87413L11.4954 6.22996L9.71621 7.94496Z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.333 12.8935C29.2488 12.6496 29.0956 12.4354 28.8921 12.2769C28.6886 12.1183 28.4434 12.0222 28.1863 12.0002L20.5996 10.8935L17.1996 4.00019C17.0905 3.77477 16.92 3.58465 16.7078 3.45162C16.4955 3.3186 16.2501 3.24805 15.9996 3.24805C15.7492 3.24805 15.5038 3.3186 15.2915 3.45162C15.0793 3.58465 14.9088 3.77477 14.7996 4.00019L11.3996 10.8802L3.81298 12.0002C3.56621 12.0353 3.33421 12.1388 3.14331 12.2991C2.95241 12.4593 2.81026 12.6699 2.73298 12.9069C2.66224 13.1384 2.65589 13.3849 2.71461 13.6198C2.77334 13.8547 2.89492 14.0692 3.06631 14.2402L8.57298 19.5735L7.23964 27.1469C7.19204 27.3968 7.21697 27.6552 7.31147 27.8915C7.40598 28.1277 7.56612 28.332 7.77298 28.4802C7.9746 28.6243 8.21241 28.7094 8.45971 28.7259C8.70701 28.7424 8.95401 28.6896 9.17298 28.5735L15.9996 25.0135L22.7996 28.5869C22.9868 28.6924 23.1981 28.7476 23.413 28.7469C23.6954 28.7479 23.9709 28.6592 24.1996 28.4935C24.4065 28.3453 24.5666 28.1411 24.6611 27.9048C24.7557 27.6685 24.7806 27.4102 24.733 27.1602L23.3996 19.5869L28.9063 14.2535C29.0988 14.0904 29.2411 13.8761 29.3166 13.6353C29.3922 13.3946 29.3978 13.1374 29.333 12.8935ZM21.133 18.2269C20.9766 18.3781 20.8596 18.5653 20.7922 18.7722C20.7248 18.979 20.7091 19.1992 20.7463 19.4135L21.7063 25.0002L16.693 22.3335C16.5001 22.2308 16.2849 22.1771 16.0663 22.1771C15.8478 22.1771 15.6326 22.2308 15.4396 22.3335L10.4263 25.0002L11.3863 19.4135C11.4236 19.1992 11.4078 18.979 11.3404 18.7722C11.273 18.5653 11.156 18.3781 10.9996 18.2269L6.99964 14.2269L12.613 13.4135C12.829 13.3835 13.0343 13.3009 13.211 13.1731C13.3876 13.0452 13.5303 12.876 13.6263 12.6802L15.9996 7.60019L18.5063 12.6935C18.6024 12.8893 18.745 13.0585 18.9216 13.1864C19.0983 13.3142 19.3036 13.3968 19.5196 13.4269L25.133 14.2402L21.133 18.2269Z"
          fill={color}
        />
      </svg>
    );
  }
};
