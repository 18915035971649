import React from "react";

type Props = {
  size?: number;
  isSameHeight?: boolean;
  height?: number;
  color?: string;
};

export const IconCheckCircle: React.FC<Props> = ({
  size = 49,
  isSameHeight = false,
  height,
  color = "#46CA6B",
}) => {
  return (
    <svg
      width={size}
      height={isSameHeight ? size : height ? height : "48"}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_2137_7411)">
        <rect x="6.5" y="2" width="36" height="36" rx="18" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7559 15.2443C32.0814 15.5699 32.0814 16.098 31.7559 16.4237L22.5974 25.5891C22.2722 25.9145 21.745 25.9148 21.4195 25.5896L17.2447 21.4198C16.9189 21.0945 16.9184 20.5664 17.2435 20.2404C17.5686 19.9144 18.0963 19.9139 18.422 20.2393L22.0076 23.8205L30.5774 15.2443C30.9028 14.9186 31.4305 14.9186 31.7559 15.2443Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2137_7411"
          x="0.5"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2137_7411"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2137_7411"
            result="effect2_dropShadow_2137_7411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2137_7411"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
